<template>
  <v-container fluid>
    <v-data-table
      :headers="itemHeader"
      :items="items"
      :search="search"
      :loading="loading"
      :items-per-page="-1"
      class="elevation-1 "
    >
      <template v-slot:top>
        <v-toolbar flat class="mb-7">
          <v-toolbar-title>Reviews</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  props: ["items", "itemHeader", "collection"],
  data() {
    return {
      search: null,
      loading: false,
      openDialog: false,
      dialogDelete: false,
      formTitle: "Add New Item",
      record: null,
    };
  },

  methods: {
    async loadData() {
      try {
        this.$emit("loadData");
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {},
};
</script>

<style></style>
